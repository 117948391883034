




























import { Component, Prop, Vue } from "vue-property-decorator";
import moment, { Moment } from "moment";
import { forEach } from "lodash";
import DaysWithEvent from "@/common/interfaces/DaysWithEvent";

@Component
export default class Calendar extends Vue {
  @Prop() activeDate!: Moment;
  @Prop() showLegend!: boolean;
  @Prop() hideToday!: boolean;
  @Prop() daysWithEvents!: DaysWithEvent[];
  @Prop() month!: Moment;
  @Prop() disabledBeforeDate!: Moment;
  @Prop() disabledAfterDate!: Moment;

  get days() {
    const monthDate: Moment = moment(this.month).startOf("month");

    const days = [...Array(monthDate.daysInMonth())].map((_, i) => {
      return {
        moment: monthDate.clone().add(i, "day"),
        class: ""
      };
    });

    forEach(this.daysWithEvents, function(dayWithEvent: DaysWithEvent) {
      forEach(dayWithEvent.days, function(eventDay: {
        day: Moment;
        dayParts: {
          morning: boolean;
          afternoon: boolean;
        };
      }) {
        forEach(days, function(
          day: { moment: Moment; class: string },
          dKey: number
        ) {
          if (
            eventDay.day.isSame(day.moment, "day") &&
            dayWithEvent.eventType === "single-event"
          ) {
            days[dKey].class +=
              " " + dayWithEvent.eventType + " " + " event-start";

            if (eventDay.dayParts.morning) {
              days[dKey].class += " morning_" + dayWithEvent.color;
            }
            if (eventDay.dayParts.afternoon) {
              days[dKey].class += " afternoon_" + dayWithEvent.color;
            }
          } else if (
            dayWithEvent.eventType === "long-event" &&
            dayWithEvent.days[0].day.isSame(day.moment, "day")
          ) {
            days[dKey].class +=
              " " +
              dayWithEvent.eventType +
              " " +
              dayWithEvent.color +
              " " +
              " event-start";
          } else if (
            dayWithEvent.eventType === "long-event" &&
            dayWithEvent.days[dayWithEvent.days.length - 1].day.isSame(
              day.moment,
              "day"
            )
          ) {
            days[dKey].class =
              dayWithEvent.eventType + " " + dayWithEvent.color + " event-end";
          } else if (
            dayWithEvent.eventType === "long-event" &&
            day.moment.isBetween(
              dayWithEvent.days[0].day,
              dayWithEvent.days[dayWithEvent.days.length - 1].day
            )
          ) {
            days[dKey].class =
              dayWithEvent.eventType + " " + dayWithEvent.color;
          }
        });
      });
    });

    return days;
  }

  column(index: number) {
    if (index == 0) {
      return this.days[0].moment.day();
    }
  }

  isWeekend(day: Moment) {
    return day.day() == 0 || day.day() == 6;
  }

  isToday(day: Moment) {
    return this.hideToday ? false : moment().isSame(day, "day");
  }

  isActiveDay(day: Moment) {
    return this.activeDate ? moment(this.activeDate).isSame(day, "day") : false;
  }

  isDisabled(day: Moment) {
    let disabled = false;
    if (this.disabledBeforeDate) {
      disabled = day.isBefore(this.disabledBeforeDate, "day");
    }

    if (disabled) return disabled;

    if (this.disabledAfterDate) {
      return day.isAfter(this.disabledAfterDate, "day");
    } else {
      return false;
    }
  }

  getMonth(date: number) {
    return moment(date).format("MMMM YYYY");
  }

  selectDay(day: Moment) {
    this.$emit("selectDate", day.startOf("day"));
  }
}
